
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import {useRoute, useRouter} from "vue-router";
import ToastService from "@/core/services/ToastService";
import {Category} from "@/core/model/category";
import {TaxonomyTerm} from "@/core/model/taxonomyTerm";
import { displayErrors } from "@/core/helpers/errors";

export default defineComponent({
  name: "mutual-catalog-edit-modal",
  emit: ["update-mutual-catalog-success"],
  components: {},
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const loading = ref<boolean>(false);
    const rules = ref({});
    const formRef = ref<null | HTMLFormElement>(null);
    const mutualCatalogEditModalRef = ref<null | HTMLElement>(null);
    const categoryOptions = ref([] as Category[]);
    const brandOptions = ref([] as TaxonomyTerm[]);
    const formData = ref({
      mutual: "",
      categories: [] as number[],
      brands: [] as TaxonomyTerm[],
    });
    const propsBrands = {
      multiple: true,
      checkStrictly: true,
      children: "childrens",
      value: "id",
      label: "name",
    };
    const propsCats = {
      multiple: true,
      checkStrictly: true,
      children: "childrens",
      value: "id",
      label: "name",
    };

    onMounted(() => {
      loadCategories();
      loadBrands();
      loadCatalog();
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          formData.value.mutual = route.params.uuid as string;
          store
              .dispatch(Actions.CATALOG_UPDATE, formData.value)
              .then(() => {
                //loadProducts();
                loading.value = false;
                emit("update-mutual-catalog-success");
                ToastService.show("Catálogo actualizado con éxito", "success");
                hideModal(mutualCatalogEditModalRef.value);
              })
              .catch(() => {
                let message = "";
                if (typeof store.state.ProductsModule.errors === "object") {
                  message = Object.values(store.state.ProductsModule.errors).join(
                      "<br>"
                  );
                } else if (Array.isArray(store.state.ProductsModule.errors)) {
                  message = store.state.ProductsModule.errors.join("<br>");
                } else {
                  message = store.state.ProductsModule.errors;
                }
                loading.value = false;
                ToastService.show(message, "error");
              });
        } else {
          ToastService.show('Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.', "error");
          return false;
        }
      });
    };

    const loadCategories = () => {
      categoryOptions.value = [];
      const params = {
        page_number: 1,
        page_size: 10000,
        sort_order: "asc",
        sort_field: "name",
      };
      store.dispatch(Actions.CATEGORY_LIST, params).then(() => {
        store.state.CategoryModule.list.items.forEach((item) => {
          categoryOptions.value.push(item);
        });
      }).catch(() => {
        displayErrors(store, "CategoryModule");
        loading.value = false;
      });;
    };

    const loadBrands = () => {
      brandOptions.value = [];
      const params = {
        page_number: 1,
        page_size: 10000,
        sort_order: "asc",
        sort_field: "name",
        filter_taxonomy_name: "Marca",
      };
      store.dispatch(Actions.BRAND_LIST, params).then(() => {
        store.state.BrandModule.list.items.forEach((item) => {
          brandOptions.value.push(item);
        });
      }).catch(() => {
        displayErrors(store, "BrandModule");
        loading.value = false;
      });;
    };

    const loadCatalog = () => {
      formData.value.categories = [] as number[];
      store.dispatch(Actions.MUTUAL_GET_CATALOG, route.params.uuid).then(() => {
        formData.value.categories =
            store.state.MutualesModule.catalog.categories;
        formData.value.brands = store.state.MutualesModule.catalog.brands;
      }).catch(() => {
        displayErrors(store, "MutualesModule");
        loading.value = false;
      });;
    };

    return {
      propsCats,
      propsBrands,
      submit,
      formData,
      formRef,
      rules,
      loading,
      categoryOptions,
      brandOptions,
      mutualCatalogEditModalRef,
    };
  },
});
